// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.swal-footer {
    text-align: center !important;
}

.pdf-wrapper {}

.react-pdf__Document {
    height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.react-pdf__Page {
    background-color: black;
}

.my-button {}

.react-pdf__message--error {
    color: white;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

@media only screen and (max-width: 800px) {
    .react-pdf__Page__textContent,
    .react-pdf__Page__canvas {
        width: 100% !important;
        aspect-ratio: 420/600;
        height: auto !important;
        height: initial !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI;QACI,4CAA4C;IAChD;AACJ;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,6BAA6B;AACjC;;AAEA,cAAc;;AAEd;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA,YAAY;;AAEZ;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;;QAEI,sBAAsB;QACtB,qBAAqB;QACrB,uBAAwB;QAAxB,0BAAwB;IAC5B;AACJ","sourcesContent":[".App {\n    text-align: center;\n}\n\n.App-logo {\n    height: 40vmin;\n    pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n        animation: App-logo-spin infinite 20s linear;\n    }\n}\n\n.App-header {\n    background-color: #282c34;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n}\n\n.App-link {\n    color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n.swal-footer {\n    text-align: center !important;\n}\n\n.pdf-wrapper {}\n\n.react-pdf__Document {\n    height: 90vh;\n    overflow-x: hidden;\n    overflow-y: auto;\n}\n\n.react-pdf__Page {\n    background-color: black;\n}\n\n.my-button {}\n\n.react-pdf__message--error {\n    color: white;\n}\n\n.react-pdf__Page__canvas {\n    margin: 0 auto;\n}\n\n@media only screen and (max-width: 800px) {\n    .react-pdf__Page__textContent,\n    .react-pdf__Page__canvas {\n        width: 100% !important;\n        aspect-ratio: 420/600;\n        height: unset !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
