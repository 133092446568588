import { Worker } from "@react-pdf-viewer/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { APP_IMP_UID, APP_RUN_MODE, KAKAO_JAVASCRIPT_KEY } from "config";
import jQuery from "jquery";
import * as pdfJsDist from "pdfjs-dist/legacy/build/pdf";
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import TokenStorage from "./helper/apiService/TokenStorage";
import RouteURL from "./routes/index";

if (!window.jQuery) {
  window.jQuery = jQuery;
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * (60 * 1000),
      cacheTime: 10 * (60 * 1000),
      retry: 2,
    },
  },
});

const { IMP } = window;
IMP.init(APP_IMP_UID);

function App() {
  useEffect(() => {
    TokenStorage.checkValidToken();
    window.Kakao?.init(KAKAO_JAVASCRIPT_KEY);
    if (process.env.NODE_ENV === "production") {
      console.log = () => {};
    }
  }, []);

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@${pdfJsDist.version}/build/pdf.worker.min.js`}
    >
      <QueryClientProvider client={client}>
        <Router>
          <RouteURL />
        </Router>
        {APP_RUN_MODE === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </Worker>
  );
}

export default App;
