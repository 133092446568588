import { APP_BASE_URL, KAKAO_REST_API_KEY } from "config";
import axiosInstance from "./AxiosInstance";
export default class TokenStorage {
  static isLoggedIn = () => {
    return this.getToken() !== undefined;
  };
  static getToken = () => {
    const appData = localStorage.getItem("appData");
    return JSON.parse(appData)?.access_token;
  };
  static getRefreshToken = () => {
    const appData = localStorage.getItem("appData");
    return JSON.parse(appData)?.refresh_token;
  };
  static clearToken = () => {
    localStorage.removeItem("appData");
  };
  static storeToken = (appData) => {
    const oldData = JSON.parse(localStorage.getItem("appData") ?? "{}");
    const newData = { ...oldData, ...appData };
    const data = JSON.stringify(newData);
    localStorage.setItem("appData", data);
  };
  static checkValidToken = () => {
    const appData = localStorage.getItem("appData");
    if (appData) {
      const appDataObj = JSON.parse(appData);
      const aTokenDate = new Date(appDataObj.expired_access_time + "Z");
      const curDate = new Date();
      if (curDate > aTokenDate) {
        this.requestNewToken();
      }
    }
  };
  static logOut = () => {
    this.clearToken();
    window.location.href = "/";
  };
  // SuhYB. 221229. Add for pass login
  static redirectPassLogin = (passToken, redirectUrl = "") => {
    if (passToken) {
      // TODO : Check if token user is registered. And do log-in or registration process.
      window.location.replace("/checkterms");
    } else {
      window.location.replace(
        `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${APP_BASE_URL}/oauth&response_type=code&state=${redirectUrl}`,
      );
    }
  };
  static redirectKakaoLogin = (redirectUrl = "", fromSidebar = false) => {
    let url;
    if (fromSidebar) {
      const state = JSON.stringify({ redirectUrl, fromSidebar: true });
      url = encodeURIComponent(state);
      console.log(state, url, "redirectKakaoLogin");
    } else {
      url = redirectUrl;
    }
    window.location.replace(
      `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${APP_BASE_URL}/oauth&response_type=code&state=${url}`,
    );
  };
  static redirectToVat = (redirectUrl = "") => {
    window.location.replace(
      `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${APP_BASE_URL}/oauth&response_type=code&state=${redirectUrl}`,
    );
  };
  static checkKakaoAuth = (code) => {
    return axiosInstance
      .get(
        `https://kauth.kakao.com/oauth/token?redirect_uri=${APP_BASE_URL}/oauth&grant_type=authorization_code&client_id=${KAKAO_REST_API_KEY}&code=${code}`,
      )
      .then((res) => res.data);
  };
  static requestNewToken = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/user/refreshtoken/", {
          headers: { Authorization: "Bearer " + this.getRefreshToken() },
        })
        .then(({ data }) => {
          if (data?.status === "success") {
            this.storeToken(data.data);
            resolve(data.data.access_token);
          }
          reject("refresh fail");
        })
        .catch((error) => {
          if (!error.response) {
            this.logOut();
          }
          reject(error);
        });
    });
  };
}
