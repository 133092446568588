import create from "zustand";
export const useSideBar = create((set) => ({
  sideBarOpen: null,
  setSideBarOpen: (stage) => set({ sideBarOpen: stage }),
}));

export const useHeaderStore = create((set) => ({
  title: null,
  showMenu: true,
  updateHeader: (title, showMenu) =>
    set((old) => ({ title, showMenu: showMenu ?? old.showMenu })),
}));

export const useLoading = create((set) => ({
  loading: false,
  isTransparent: true,
  setLoadingState: (loading, isTransparent = true) =>
    set({
      loading,
      isTransparent,
    }),
}));

// OLD
export const useUserInfoStore = create((set) => ({
  userInfo: {},
  loggedInType: null,
  homeTaxFetchStatus: null,
  updateUserInfo: (userInfo, loginType) => set({ userInfo, loginType }),
  updateHomeTaxFetchStatus: (homeTaxFetchStatus) => set({ homeTaxFetchStatus }),
}));

export const useFamilyRelationsStore = create((set) => ({
  familyRelation: null,
  setFamilyRelation: (familyRelation) => set({ familyRelation }),
}));

export const useModalStore = create((set) => ({
  isOpen: false,
  header: <span></span>,
  content: <span></span>,
  onConfirm: null,
  showModal: ({ header, onConfirm, content }) =>
    set({ isOpen: true, header, content, onConfirm }),
  closeModal: () => set((state) => ({ ...state, isOpen: false })),
}));

export const useSelectModalStore = create((set) => ({
  isOpen: false,
  header: <span></span>,
  content: <span></span>,
  cancelText: "취소",
  confirmText: "확인",
  onCancel: null,
  onConfirm: null,
  showModal: ({
    header,
    content,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
  }) =>
    set({
      isOpen: true,
      header,
      content,
      confirmText,
      cancelText,
      onConfirm,
      onCancel,
    }),
  closeModal: () => set((state) => ({ ...state, isOpen: false })),
}));

export const useSlideUpStore = create((set) => ({
  isOpen: false,
  content: "",
  hasCloseButton: true,
  openSlideUp: (content, hasCloseButton = true) =>
    set((old) => ({ ...old, isOpen: true, content, hasCloseButton })),
  closeSlideUp: () =>
    set((old) => ({ ...old, isOpen: false, hasCloseButton: true })),
}));

export const slideUpSelector = (state) => ({
  openSlideUp: state.openSlideUp,
  closeSlideUp: state.closeSlideUp,
});

export const useVatInfo = create((set) => ({
  fetchedBizNo: false,
  updateVatInfo: (data) => set((old) => ({ ...old, ...data })),
  resetVat: () =>
    set({
      fetchedBizNo: false,
    }),
}));

export const useUserFamily = create((set) => ({
  userFamilies: [],
  setUserFamilies: (userFamilies) => set({ userFamilies }),
}));

export const useYECInfo = create((set) => ({
  newFetchStatus: null,
  selectedModeServer: null,
  readyToShowMain: null,
  updateYECData: ({ newFetchStatus, readyToShowMain, selectedModeServer }) =>
    set((old) => ({
      newFetchStatus: newFetchStatus ?? old.newFetchStatus,
      readyToShowMain: readyToShowMain ?? old.readyToShowMain,
      selectedModeServer: selectedModeServer ?? old.selectedModeServer,
    })),
  resetYEC: () =>
    set({
      newFetchStatus: null,
      readyToShowMain: null,
      selectedModeServer: null,
    }),
}));

export const useYESInfo = create((set) => ({
  readyToShowMain: null,
  registeredUser: null,
  newFetchStatus: null,
  updateYESData: ({ readyToShowMain, newFetchStatus }) =>
    set((old) => ({
      ...old,
      readyToShowMain: readyToShowMain ?? old.readyToShowMain,
      newFetchStatus: newFetchStatus ?? old.newFetchStatus,
    })),
  resetYES: () =>
    set({
      readyToShowMain: null,
    }),
}));

export const useUPCInfo = create((set) => ({
  inputStepStatus: null,
  updateUPCData: ({ inputStepStatus }) =>
    set((old) => ({
      ...old,
      inputStepStatus: inputStepStatus ?? old.inputStepStatus,
    })),
  resetUpc: () => set({ inputStepStatus: null }),
}));

export const useRPCInfo = create((set) => ({
  inputStepStatus: null,
  updateRPCData: ({ inputStepStatus }) =>
    set((old) => ({
      ...old,
      inputStepStatus: inputStepStatus ?? old.inputStepStatus,
    })),
  resetRpc: () => set({ inputStepStatus: null }),
}));

export const useBBCInfo = create((set) => ({
  inputStepStatus: null,
  updateBBCData: ({ inputStepStatus }) =>
    set((old) => ({
      ...old,
      inputStepStatus: inputStepStatus ?? old.inputStepStatus,
    })),
  resetBbc: () => set({ inputStepStatus: null }),
}));

export const useMSCInfo = create((set) => ({
  inputStepStatus: null,
  updateMSCData: ({ inputStepStatus }) =>
    set((old) => ({
      ...old,
      inputStepStatus: inputStepStatus ?? old.inputStepStatus,
    })),
  resetRpc: () => set({ inputStepStatus: null }),
}));

export const useRedirectSignal = create((set) => ({
  urlSignal: null,
  setUrlSignal: (urlSignal) => set({ urlSignal }),
}));

export const useVatPeriod = create((set) => ({
  vatPeriod: "" /* AppConfig.vat.vatServicePeriod */,
  setVatPeriod: (vatPeriod) => set({ vatPeriod }),
}));

/**
 * 부가세 신고할 때 간편인증이 필요한지 체크하는 파라미터
 * -> 신뢰하기 어려운 값이므로 잠깐 데이터를 저장하고 사용하는 경우 외에는 사용하지 말것
 */
export const useCheckVatAuthStatus = create((set) => ({
  isVatAuthNeeded: true,
  isVatLoginNeeded: true,
  setIsVatAuthNeeded: (isVatAuthNeeded) => set({ isVatAuthNeeded }),
  setIsVatLoginNeeded: (isVatLoginNeeded) => set({ isVatLoginNeeded }),
}));

/**
 * @deprecated
 * 결제 Payment.jsx 프로세스를 탈때 저장하기 어려운 extra 값을 캐싱
 * Redirect 로 인해 데이터가 리셋되는 문제로 사용하지 않음
 */
export const usePaymentExtra = create((set) => ({
  paymentExtra: {},
  paymentExtraId: "",
  updatePaymentExtra: (paymentExtra) =>
    set((state) => ({
      ...state,
      paymentExtra: paymentExtra ?? {},
    })),
  updatePaymentExtraId: (paymentExtraId) =>
    set((state) => ({
      ...state,
      paymentExtraId: paymentExtraId ?? "",
    })),
}));
