import PageLoading from "components/Commons/PageLoading/PageLoading";
import AppURLs from "pages/AppInfo/AppUrl";
import React, { Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

const MobileLandingNew = React.lazy(() =>
  import("../pages/MobileLandingNew/MobileLandingNew"),
);
const KakaoLogout = React.lazy(() =>
  import("../components/Commons/KakaoLogout/KakaoLogout"),
);
const KakaoAuth = React.lazy(() =>
  import("../components/Commons/KakaoAuth/KakaoAuth"),
);
const MobileLandingEtc = React.lazy(() =>
  import("../pages/MobileLandingNew/MobileLandingEtc"),
);

const GA4_TAG_ID = process.env.REACT_APP_GOOGLE_ANALYTICS;
const IS_DEBUG_MODE = process.env.REACT_APP_IS_STAGING;
const ENABLE_GA4 = IS_DEBUG_MODE ? false : true;

export default function RouteURL() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  // 구글 애널리틱스 운영서버만 적용
  useEffect(() => {
    if (GA4_TAG_ID) {
      // 초기화
      ReactGA.initialize(GA4_TAG_ID);
      setInitialized(true);
      // 디버그 모드 설정
      if (IS_DEBUG_MODE) {
        ReactGA.gtag("config", GA4_TAG_ID, {
          debug_mode: true,
        });
      }
    }
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      if (ENABLE_GA4) {
        ReactGA.set({ page: location.pathname });
        ReactGA.send("pageview");
      }
    }
  }, [initialized, location]);

  return (
    <Suspense fallback={<PageLoading isLoading isTransparent={false} />}>
      <Switch>
        <Route path="/oauth">
          <KakaoAuth />
        </Route>
        <Route path="/logout">
          <KakaoLogout />
        </Route>
        <Route path={AppURLs.etc.root}>
          <MobileLandingEtc />
        </Route>
        <Route path="/">
          <MobileLandingNew />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}
