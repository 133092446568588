import axios from "axios";
import TokenStorage from "./TokenStorage";
import { BASE_API_URL, SAVEIT_API_URL } from "../../config";

export const axiosPublicRAPI = axios.create({
  baseURL: SAVEIT_API_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosDownloader = axios.create({
  timeout: 300000,
});

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(function (config) {
  const token = TokenStorage.getToken();
  if (config.url !== "/user/refreshtoken/") {
    config.headers.Authorization = token ? `Bearer ${token}` : null;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (
      error.config.url === "/user/refreshtoken/" ||
      error.response.message === "Account is disabled."
    ) {
      TokenStorage.logOut();
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Try request again with new token
    return TokenStorage.requestNewToken()
      .then((token) => {
        // New request with new token
        const config = error.config;
        config.headers["Authorization"] = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
);
export default axiosInstance;
