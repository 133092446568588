const AppURLs = {
  root: "/",
  common: {
    redirectAfterPayment: "/redirectAfterPayment",
    redirectToAuth: "/redirectToAuth",
    requestAuth: "/requestEasyAuth",
    processing: "/processing",
    fetchFail: "/fetchfail",
    serviceUnavailable: "/unavailable",
    errorRedirect: "/error/redirect",
    withdrawal: "/withdrawal",
    policy: "/policy",
    privacy: "/privacy",
    planList: "/planlist",
    thirdParty: "/thirdparty",
    changePhoneNum: "/changephonenum",
    registerHomeTax: "/registerHomeTax",
    removeData: "/removeData",
    completePayment: "/completePayment/:from/:data",
    referral: "/invite/:referral_code",
    referralRank: "/referralRank",
    sqa: "/sqa",
    yecIntroReferral: "/yec-intro/:referral_code",
    yecIntro: "/yec-intro",
    yesiIntroReferral: "/yesi-intro/:referral_code",
    yesiIntro: "/yesi-intro",
    stitIntroReferral: "/stit-intro/:referral_code",
    stitIntro: "/stit-intro",
    citIntroReferral: "/cit-intro/:referral_code",
    citIntro: "/cit-intro",
    httIntroReferral: "/htt-intro/:referral_code",
    httIntro: "/htt-intro",
    welcome: "/welcome",
    mobileLanding: "/inappauth",
    logout: "/logout",
    // 22.12.28. 로그인 후 회원등록 프로세스 : 약관 동의 -> 본인인증 -> 회원 통합 -> 가입완료
    // Set the after-login process : Terms and services -> Identification -> Merge account -> Complete registration
    checkterms: "/checkterms",
    mergeaccount: "/mergeaccount",
    editUserInfo: "/edituserinfo",
  },
  event: {
    friendCouponEvent: "/event/friendCouponEvent",
    friendCouponBox: "/event/friendCouponBox",
    friendCoupon: "/event/friendCoupon/:coupon_code",
  },
  promotion: "/promotion/:promotion_code",
  payment: {
    fail: "/payment/fail", // Routing -> TaxPayment 에서 처리
    faq: "/payment/faq", // Routing -> TaxPayment 에서 처리
    root: "/payment",
    couponBox: "/cit/couponBox",
  },
  bank: {
    root: "/bank",
    confirmBank: "/bank/confirm",
  },
  cit: {
    root: "/cit",
    general: "/cit/general",
    correctionRequestAnalysis: "/cit/correctionRequestAnalysis",
    updateTaxRequestProof: "/cit/updateTaxRequestProof",
    report: {
      list: "/cit/report",
      reportFail: "/cit/report/fail",
      reportInProcessing: "/cit/report/processing",
      refundNotComplete: "/cit/report/refundNotComplete",
      cancelReportFail: "/cit/report/cancelFail",
      cancelReportSuccess: "/cit/report/cancelSuccess",
      completeReport: "/cit/completeReport",
      completeSpecialReport: "/cit/completeSpecialReport",
      inputFamilyRRN: "/cit/report/inputFamilyRRN/:year",
      buildCancelReport: (report_id) => `/cit/report/${report_id}/cancel`,
      buildYearConsentTax: (year) => `/cit/consentTax/${year}`,
      buildYearReportSummary: (year) => `/cit/summaryReport/${year}`,
    },
    writeReport: {
      root: "/cit/writeReport",
    },
    planCheck: "/cit/planCheck",
    buildPreProcess: (year) => `/cit/preProcess/${year}`,
    buildReportDetail: (id) => `/cit/report/detail/${id}`,
    buildReportCancelFail: (id) => `/cit/report/${id}/cancelFail`,
    buildYearReportDetail: (year) => `/cit/${year}/report`,
    buildRequestReportFinish: (year) => `/cit/requestReportFinish/${year}`,
    buildRequestReportFail: (year) => `/cit/requestReportFail/${year}`,
    buildYearOverview: (year) => `/cit/${year}`,
  },
  vat: {
    root: "/vat",
  },
  vatMng: {
    root: "/vat-management",
  },
  yec: {
    root: "/yec",
  },
  yes: {
    root: "/yes",
    fetchDeductionFail: "/yes/dataConsent/fail",
  },
  yesi: {
    root: "/yesi",
  },
  stit: {
    root: "/stit",
  },
  rpc: {
    root: "/rpc",
  },
  upc: {
    root: "/upc",
  },
  msc: {
    root: "/msc",
  },
  frc: {
    root: "/frc",
  },
  myPage: {
    root: "/home",
  },
  bbc: {
    root: "/bbc",
  },
  tad: {
    root: "/tad",
  },
  htt: {
    root: "/htt", // 양도세 root 추가, HttPage/httRoute 참고
  },
  reviews: {
    root: "/reviews",
    referral: "/reviews/:referral_code",
    addReview: "/reviews/save",
    yearSelect: "/reviews/yearSelect",
  },
  etc: {
    root: "/etc",
    stockResult: "/etc/stockResult/:filename",
    // @deprecated: CIT 경정청구 - 서면신고서 외부 다운로드 페이지
    writtenReportDownload: "/etc/writtenReport/:filename",
    vatReportDownload: "/etc/vatReport/:filename",
    componentTest: "/etc/componentTest",
  },
  setYear: (url, year) => url.replace(":year", year),
};
export default AppURLs;
