export const APP_IMP_UID = process.env.REACT_APP_IMP_UID;
export const APP_RUN_MODE = process.env.REACT_APP_RUN_MODE;
export const IS_STAGING = process.env.REACT_APP_IS_STAGING;

export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const SAVEIT_API_URL = process.env.REACT_APP_SAVEIT_API_URL;
export const SAVEIT_VAT_API_URL = process.env.REACT_APP_SAVEIT_VAT_API_URL;
export const SAVEIT_KB_API_URL = process.env.REACT_APP_SAVEIT_KB_API_URL;
export const SAVEIT_EASY_AUTH_MODE = process.env.REACT_APP_EASY_AUTH_MODE;

export const TOSS_PAYMENT_KEY = process.env.REACT_APP_TOSS_PAYMENT_KEY;
export const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS;
export const KAKAO_JAVASCRIPT_KEY = process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY;
export const KAKAO_REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API_KEY;

// export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
